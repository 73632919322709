
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: {},
  methods: {},
  computed: {},
})
  export default class ViewContainerCard extends Vue {
  width = 0
  @Prop({ type: Number }) containerHeight!: number
  @Prop({ type: Number }) containerWidth!: number
  @Prop({ type: Number }) minHeight!: number
  @Prop({ type: Number }) maxHeight!: number
  @Prop({ type: Number, default: false }) isResize!: number
  @Prop({ type: Boolean, default: false }) isMobile!: boolean
  @Prop({ type: Boolean, default: false }) overflowOff!: boolean

  @Watch('isResize', { immediate: true }) onResizeChange () {
    this.width = this.getWidth()
  }

  getWidth () {
    const { isMobile, containerWidth } = this

    return isMobile ? containerWidth : 414
  }
  }
